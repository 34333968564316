import { logo } from '~/helpers/svg';
import Link from 'next/link'

export default function Maintenance() {
  return (
    <div className="page--error">
      <div>
        <span>{logo}</span>
      </div>
      <section>
        <div>
          <b>!!</b> <p>Nuestros sistemas no están disponibles por el momento</p>
        </div>
        <h2>TIENDA EN LÍNEA</h2>
        <p>
        Lamentamos informarle que estamos experimentando inconvenientes con
          nuestros sistemas en este momento. Estamos trabajando para resolverlos
          a la brevedad posible, nos disculpamos por cualquier molestia que esto
          pueda causarle.
          <br />
          <br />
          ¡Volveremos muy pronto!
        </p>
        <Link href="/">Ir a la página principal</Link>
      </section>
      <aside>
        <img src="/media/layout/error500.png" alt="Error 500" />
      </aside>
    </div>
  );
}
